<template>
  <div class="Index">
    <!-- 新头部轮播图 -->
    <div class="top-banner">
      <el-carousel ref="elCarousel" trigger="click" height="752px" @change="topBannerChange" indicator-position="none"
        :autoplay="topBannerAuto">
        <el-carousel-item v-for="(item, index) in topBannerData" :key="index">
          <img class="top-img" :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>

      <div class="top-banner-white">
        <div class="banner-icon-container" :class="{ 'banner-icon-active': bannerActive == index }"
          v-for="(item, index) in topBannerIconData" @mouseenter="changeBannerImg(index)" @mouseleave="topBannerLeave()"
          :key="index">
          <img :src="bannerActive == index ? item.activedUrl : item.icon" alt="">
          <div class="name">{{  item.name  }}</div>
          <div class="name" style="margin-top:8px">{{  item.platform  }}</div>
        </div>
      </div>
    </div>


    <!-- 服务优势 -->
    <div class="banxin serve-container ">
      <h3>服务优势</h3>
      <div class="serve-box">
        <div class="serve-content" :class="serviceIsChose == index ? 'serve-content-actived' : ''"
          @click="gotoPage(item.path)" v-for="(item, index) in serveData " :key="index" @mouseover="changeStyle(index)"
          @mouseleave="serviceMouseLeave">
          <img :src="serviceIsChose == index ? item.imgActived : item.img" alt="">
          <div class="title">{{  item.title  }}</div>
          <div class="desc">{{  item.desc  }}</div>
        </div>
      </div>

    </div>
    <!-- 解决方案 -->
    <div class="solution">
      <div class="solution-title">
        <img class="dot" src="@/assets/images/home/solution-left-dot.png">
        <h3>解决方案</h3>
        <img class="dot" src="@/assets/images/home/solution-right-dot.png">
      </div>

      <!-- 轮播图 -->
      <div class="swiper-container" ref="swiperServe">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in image" :key="item.id">
            <div @mouseenter="casesMouseenter(item.id)" @mouseleave="casesMouseLeave()" @click="gotoPage(item.path)"
              class="photo" :style="item.url ? `background: url(${item.url})` : null">
              <div class="photo-item">
                <div class="title">{{  item.title  }}</div>
                <div :class="[
                  item.id == casesActive ? 'Multi-line' : 'tow-line',
                  'content',
                ]">
                  {{  item.content  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要滚动条 -->
        <div class="swiper-scrollbar"></div>
      </div>
    </div>


    <!-- 新闻资讯 -->
    <div class="news-container banxin">
      <div class="news">
        <div class="title-en">Newsroom</div>
        <div class="title-zn">新闻资讯
          <div class="news-more" @click="neKnowMore">查看更多></div>
        </div>

        <div class="news-item-container">
          <div class="list">
            <div class="list-container" :class="newsShow == index ? 'news-active' : ''"
              v-for="(item, index) in newsData" :key="index" @click="newsClick('/news/' + item.newsId)"
              @mouseenter="newShow(item, index)">
              <img class="small-image" :src="item.newsPicture ? item.newsPicture : smallDefaultImg" alt=""
                v-show="newsShow == index">
              <div class="list-news-text" :class="newsShow == index ? '' : 'news-unactive'">
                <div class="time">{{  item.newsTime | dateFormat  }}</div>
                <div class="news-list-title">{{  item.newsDescribe  }}</div>
                <img class="more" src="@/assets/images/home/more.png" v-show="newsShow == index" />
              </div>
            </div>

          </div>
          <div class="big-img">
            <img class="news-right-img" :src="newsBigImage" alt="">
            <div class="modal-text">
              <div class="time">{{  newsDateRight | dateFormat  }}</div>
              <div class="title" v-html="newsDateDesc"></div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 联系我们 -->
    <div class="contact-us-container">
      <div class="contact-us-content">
        <div class="text">
          <div class="title">联系我们</div>
          <div class="desc">宝晟（广州）信息技术有限公司专注于为医药、生物技术、生态环
            境等领域的用户提供样本资源和实验室业务的流程和数据管理信息化解
            决方案；为用户提供软件系统的设计、开发、业务数据处理、数据分析
            及系统集成实施、运行维护服务。公司用户覆盖全国二十余个省市及香
            港特别行政区，在广州、北京、上海、成都设立了服务中心。</div>
          <div class="btn" @click="gotoAbout">了解更多</div>
          <img class="logo" src="@/assets/images/home/contact-us-logo.png" />
        </div>
        <div class="img">
          <img src="@/assets/images/home/contact-us-hands.png" alt="">
        </div>
      </div>

    </div>
  </div>
</template>
<!-- <script type="text/javascript" src="http://pv.sohu.com/cityjson?ie=utf-8"></script>  -->
<script>
// @ is an alias to /src
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import axios from 'axios'
import { getNewsListApi, saveUserinfoApi } from "@/request/api"
export default {
  name: 'Index',
  data() {
    return {
      serviceIsChose: 10086,//服务优势选中效果
      topBannerAuto: true,
      bannerActive: 0, //顶部轮播图选中状态
      //头部轮播图数据
      topBannerData: [
        {
          url: require("@/assets/images/home/topBanner/home-top-banner01.png")
        },
        {
          url: require("@/assets/images/home/topBanner/home-top-banner02.png")

        },
        {
          url: require("@/assets/images/home/topBanner/home-top-banner03.png")
        },
        {
          url: require("@/assets/images/home/topBanner/home-top-banner04.png")
        },
        {
          url: require("@/assets/images/home/topBanner/home-top-banner05.png")
        },
        {
          url: require("@/assets/images/home/topBanner/home-top-banner06.png")
        },
        {
          url: require("@/assets/images/home/topBanner/home-top-banner07.png")
        },
      ],
      topBannerIconData: [
        {
          icon: require("@/assets/images/home/topBanner/homeBanner-icon01.png"),
          activedUrl: require("@/assets/images/home/topBanner/homeBanner-icon-actived01.png"),
          name: "海洋资源实验室",
          platform: "管理平台"
        },
        {
          icon: require("@/assets/images/home/topBanner/homeBanner-icon02.png"),
          name: "科考资源信息数据",
          platform: "管理平台",
          activedUrl: require("@/assets/images/home/topBanner/homeBanner-icon-actived02.png")
        },
        {
          icon: require("@/assets/images/home/topBanner/homeBanner-icon03.png"),
          name: "种质资源库信息",
          platform: "管理平台",
          activedUrl: require("@/assets/images/home/topBanner/homeBanner-icon-actived03.png")

        },
        {
          icon: require("@/assets/images/home/topBanner/homeBanner-icon04.png"),
          name: "医疗机构生物",
          platform: "样本库管理",
          activedUrl: require("@/assets/images/home/topBanner/homeBanner-icon-actived04.png")

        },
        {
          icon: require("@/assets/images/home/topBanner/homeBanner-icon05.png"),
          name: "多中心研究数据与",
          platform: "样本管理",
          activedUrl: require("@/assets/images/home/topBanner/homeBanner-icon-actived05.png")

        },
        {
          icon: require("@/assets/images/home/topBanner/homeBanner-icon06.png"),
          name: "药企细胞存储管理",
          platform: "信息系统",
          activedUrl: require("@/assets/images/home/topBanner/homeBanner-icon-actived06.png")

        },
        {
          icon: require("@/assets/images/home/topBanner/homeBanner-icon07.png"),
          name: "物联网监控及数据",
          platform: "管理平台",
          activedUrl: require("@/assets/images/home/topBanner/homeBanner-icon-actived07.png")

        },
      ],
      //服务优势
      serveData: [
        {
          img: require("../assets/images/home/serve-01.png"),
          imgActived: require("../assets/images/home/serve-01.gif"),
          title: "定制开发",
          path: "/customDev",
          desc: "宝晟拥有先进的软件技术平台和高水平实施顾问，量身定制贴合用户实际应用需求的信息管理系统。",
        },
        {
          img: require("../assets/images/home/serve-02.png"),
          imgActived: require("../assets/images/home/serve-02.gif"),
          title: "技术平台",
          path: "/techPlatform",
          desc: "基于先进IT技术的宝晟C-Lims低代码平台和宝晟PaaS云平台，为各类需求应用场景搭建高性能的数字化业务管理系统。",
        },
        {
          img: require("../assets/images/home/serve-03.png"),
          imgActived: require("../assets/images/home/serve-03.gif"),
          title: "数据服务",
          path: "/dataServices",
          desc: "宝晟云平台数据处理架构，采用NLP自然语言处理技术，构建数据集和元数据体系，通过数据采集、数据清洗以及结构化转换，进行数据整合、数据挖掘。",
        },
        {
          img: require("../assets/images/home/serve-04.png"),
          imgActived: require("../assets/images/home/serve-04.gif"),
          title: "实施顾问",
          path: "/workIssue",
          desc: "实施顾问专家为用户梳理、重建业务流程，构建数据中心，提供相应行业领域信息化、数字化建设的顶层设计建议。",
        },
      ],

      newsDateRight: "2022-08-01", //新闻时间
      newsDateDesc: "",//新闻描述
      newsShow: 0,      //新闻资讯 鼠标hover效果
      smallDefaultImg: require("@/assets/images/home/news-item-small.png"),
      newsBigImage: require("@/assets/images/home/news-item-big.png"),  //新闻资讯 右边大图
      newsData: [
        {
          newsDescribe: "首届浙江生命健康大数据大会召开，推动生物医药全产业链布局..",
          newsTime: "2022-7-17",
          smallImg: require("@/assets/images/home/news-item-small.png"),
          bigImg: ""
        },
        {
          newsDescribe: "医药生物行业7月15日流向日报",
          newsTime: "2022-7-17",
          smallImg: require("@/assets/images/home/news-item-small.png"),
          bigImg: ""
        },
        {
          newsDescribe: "医药生物行业7月15日资金流向日报医药生物行业7...",
          newsTime: "2022-7-17",
          smallImg: require("@/assets/images/home/news-item-small.png"),
          bigImg: ""
        },
        {
          newsDescribe: "医药生物行业7月15日资金流向",
          newsTime: "2022-7-17",
          smallImg: require("@/assets/images/home/news-item-small.png"),
          bigImg: ""
        },
      ],
      // 解决方案
      casesActive: '',
      image: [
        {
          id: '1',
          path: "/bioBank",
          url: require('@/assets/images/home/indexImg/img1.png'),
          title: '医疗机构生物样本库管理系统',
          content:
            '医疗机构生物样本库的样本种类多、病种多、临床信息数据多、环节多、关联科研项目多，不同机构基于自身对样本库的设计和运行模式不同，样本管理具有复杂的个性化的业务流程和信息数据管理需求，在遵循20387标准的前提下，要求信息管理系统能对样本全生命周期进行精细化管理。',
        },
        {
          id: '2',
          path: "/germBank",
          url: require('@/assets/images/home/indexImg/img2.png'),
          title: '种质资源库管理系统',
          content:
            '种质资源库以履行《生物多样性公约》，保护生物多样性、持续利用其组成部分以及互惠共享遗传资源为建设和运行目标，要成为生物多样性研究与保护的重要载体。种质资源库信息管理系统需要管理包括样品资源、遗传数据资源、天然产物资源、活体资源等，对种质资源信息数据分级必须建立安全、可行的开放共享机制，能够实现在线分级共享。',
        },
        {
          id: '3',
          path: "/medicineBank",
          url: require('@/assets/images/home/indexImg/img3.png'),
          title: '药企样品管理系统',
          content:
            '生物制药企业样本库信息管理系统要求结合相关法规、企业SOP，对样品（细胞、抗体等）的入库、质检、出库领用、销毁等流程进行计算机化管理，以实现样品全生命周期的数据追踪和审计要求。',
        },
        {
          id: '4',
          path: "/multicenter",
          url: require('@/assets/images/home/indexImg/img4.png'),
          title: '多中心研究样本与数据管理平台',
          content:
            '近几年临床多中心研究越来越受到重视，基于我国众多人口基础上的病源优势，临床多中心研究可获得客观、准确，避免单一研究可能存在的局限性，对指导临床实践有较广泛的意义。其中，在多中心自然人群队列建设中，需要跨中心，跨学科，跨地域收集样本，建立生物样本库，需要采集问卷信息，病历信息、检验检查和实验室实验数据、随访数据信息等。建设数据管理平台支撑多中心自然人群队列建设和研究工作，所涉及的数据来源于多个方面，不仅仅要规范数据信息标准，以便于后续研究工作中的数据清洗、数据整合、数据利用，并且在信息数据安全方面，必须符合《网络安全法》、《数据安全法》、《个人信息保护法》、《健康医疗数据安全指南》和《人类遗传资源管理条例》等一系列法律法规要求。',
        },
        {
          id: '5',
          path: "/germBank",
          url: require('@/assets/images/home/indexImg/img5.png'),
          title: '种质资源库管理系统',
          content:
            '种质资源库以履行《生物多样性公约》，保护生物多样性、持续利用其组成部分以及互惠共享遗传资种质资源库以履行《生物多样性公约》，保护生物多样性、持续利用其组成部分以及互惠共享遗传资',
        },
        {
          id: '6',
          path: "/envBank",
          url: require('@/assets/images/home/indexImg/img6.png'),
          title: '环境样本管理系统',
          content:
            '环境样本库是研究环境当时状态和随时空发展变化的一项重要工具，环境样本库通常选择在当时生态环境状态下具有代表性的样品，如大气样品、土壤样品、水样品和沉淀物样品、以及环境生物样品等，对这些样品进行系统性的采集、制备，提供必要条件用于长期而稳定地保存。通过环境样本库信息管理系统，建立业务流程管理制度，实现样品从采集、入库到研究使用的规范化管理；通过环境样本库信息管理系统，建立样品数据库，管理库存样品存储信息，管理库存样品分析研究数据；通过物联网技术和GIS技术动态采集环境数据并与库存样本匹配，从而建成样品与环境信息数据中心。环境样本库通过信息管理系统建立对外分级共享机制，实现资源共享。',
        },
        {
          id: '7',
          path: "/science",
          url: require('@/assets/images/home/indexImg/img7.png'),
          title: '科研项目管理系统',
          content:
            '随着科学事业的发展，我国在科研方面每年都需要投入大量的资金，支持和完成重大科研项目。如何规范管理科研项目的实施，保障国家重大科研项目顺利组织和开展，确保科研资金发挥成效，就显得非常重要。科研项目管理应该以项目管理为业务核心，涵盖项目申报、形式审查、线上评审、立项审批、签订合同、项目进展、项目变更、项目终止、验收结题、科研审批、科研登记、评奖和考核等业务流程和数据管理功能，实现规范化、精细化、科学化、信息化管理目标。通过建设科研项目管理系统，为科研项目的实施提供信息化管理平台支撑，以提升科研项目管理效率，加快科研项目审核、实施、经费使用进度，实时统计汇总分析，为科学决策提供依据，推动建立权责清晰、管理科学、运行高效、监督有力的科研项目管理工作制度。',
        },
        {
          id: '8',
          path: "/gene",
          url: require('@/assets/images/home/indexImg/img8.png'),
          title: '基因数据库管理系统',
          content:
            '生命科学时代，人类已经越来越认识到生物遗传基因数据对国民健康、保护生物多样性和生物产业发展具有举足轻重的作用。随着基因测序技术的不断突破，基因测序成本直线下降，为生命科学领域带来了重大变革，基因资源的开发利用将占据未来全球生物产业链发展的战略制高点，而构建基因数据库是是对基因数据进行有效保存、管理和合理利用的最好管理方式。建设基因数据库管理系统可以提高对基因数据的储存、分析和管理能力，促进基因及数据资源共享利用，有利于维护生物信息安全，增强在生命科学大数据时代的科技创新能力。',
        },
        {
          id: '9',
          path: "/compound",
          url: require('@/assets/images/home/indexImg/img9.png'),
          title: '化合物库信息管理系统',
          content:
            '对于医药研发企业、CRO、科研机构等在研发过程中所产生的大量化合物、天然产物以及与之相关的科学数据，是研究机构和企业的宝贵财富，同时也是研究机构和企业研究水平和核心竞争力的体现。采用信息管理系统管理化合物的收集、处理和存储，科学规范管理化合物的信息数据，帮助管理者及时掌握研发项目进度，通过化合物结构式查询，随时了解历史研发信息，及时分享研发信息，加快研发进程，充分实现历史化合物研发信息被参考和重复利用，节约企业资源。',
        },
        {
          id: '10',
          path: "/experimentCenter",
          url: require('@/assets/images/home/indexImg/img10.png'),
          title: '实验中心一体化信息管理平台',
          content:
            '实验中心是科研工作的主要运行平台，是从事科学研究实验工作的基地，实验室中心不仅要管理“人、机、物、法、环”，而且承担项目、课题的研究工作，需要对项目的过程、经费的使用进行科学合规管理。又因为科研工作的创造性，需要对实验数据进行采集整理、存储，统计学分析，在新的大环境下，科学研究趋向以多中心、多合作机构联合方式共同完成，在科研工作中产生海量的试验数据也成为新型的实验室资源，因而更要求实现信息数据共享，实验资源共享，实现高效率的协同办公。',
        },
        {
          id: '11',
          path: "/instrumentShare",
          url: require('@/assets/images/home/indexImg/img11.png'),
          title: '仪器设备共享管理系统',
          content:
            '随着的科研水平发展，以及实验室环境建设，大型仪器设备日益增多，迫切需要加强科技基础条件平台建设并建立有效的共享机制，以“整合、共享、完善、提高”为原则，减少重复购置和资金浪费，促进实验室资源的专管共享，最大限度地发挥实验室仪器设备及其相关资源的使用效率。共享即意味着实验室将会对外更加地开放，对实验室管理者来说，如何高效率的共享使用仪器？如何科学地开展实验室的各项活动？如何提高日常工作效率？如何更加便利地服务于实验人员？如何保障实验室安全？建设仪器设共享管理信息系统，可覆盖各类科研设施与仪器的统一规范管理，实现专业化、智能化、数字化管理，建立完整的，高效的，安全的仪器设备数字化管理服务平台。',
        },
        {
          id: '12',
          path: "/clinic",
          url: require('@/assets/images/home/indexImg/img12.png'),
          title: '临床研究统计分析平台',
          content:
            '在临床研究工作中，科研人员尽管面对着庞大的医疗信息数据资源，但是由于统计分析工具学习难度大，时间耗费大，投入经费大，临床数据分析工具使用成本极高，甚至经常误用统计方法，阻碍科研工作者的科研成果输出，也浪费了宝贵的医疗信息数据资源。临床科研工作者需要高效便捷的医疗数据分析平台，能够高效完成数据预处理，提升数据资源利用率，并能以最优匹配的结果智能寻参、智能建模。',
        },
      ],

    }
  },
  created() {
    console.log(11)
    this.getNewsList()
    console.log(returnCitySN);
    this.saveUserInfo()
    // returnCitySN["cip"] + ',' + returnCitySN["cname"], returnCitySN
  },
  mounted() {
    let mySwiper = new Swiper('.swiper-container', {  //解决方案轮播图
      direction: 'horizontal', // 垂直切换选项
      mousewheel: true, //滚轮
      loop: true, // 设置为true 则开启loop模式
      slidesPerView: 4, // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
      // centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
      // spaceBetween: 20, // 在slide之间设置距离（单位px）。
      loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
      // grabCursor: true,//鼠标抓手
      autoplay: {
        //自动开始
        delay: 2000, //时间间隔
        disableOnInteraction: false, //*手动操作轮播图后不会暂停*
      },
      loop: true, // 循环模式选项
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
    // //鼠标移入停止播放，鼠标离开  继续轮播
    mySwiper.el.onmouseover = function () {
      mySwiper.autoplay.stop();
    }
    mySwiper.el.onmouseleave = function () {
      mySwiper.autoplay.start();
    }

  },
  methods: {
    topBannerChange() { //顶部轮播图change事件
      this.bannerActive = this.$refs.elCarousel.activeIndex

    },
    changeStyle(index) { //添加边框 和 文字颜色
      this.serviceIsChose = index
    },
    serviceMouseLeave() { // 服务优势 鼠标移出效果
      this.serviceIsChose = 10086
    },
    changeBannerImg(i) { //切换banner图
      this.bannerActive = i;
      this.$refs.elCarousel.activeIndex = i
      this.topBannerAuto = false;

    },
    topBannerLeave() {
      this.topBannerAuto = true
      // this.bannerActive = 1
    },

    newShow(item, i) {
      this.newsShow = i;
      this.newsDateRight = item.newsTime //更新右边新闻时间
      let newsContent = item.newsContent
      if (newsContent) {
        this.newsDateDesc = newsContent.match(/<p[^>]*>([\s\S]*?)<\/p>/)[0]
      }
      if (item.newsPicture) {
        this.newsBigImage = item.newsPicture
      } else {
        this.newsBigImage = require("@/assets/images/home/news-item-big.png")
      }
    },
    casesMouseenter(id) { //解决方案轮播图
      this.casesActive = id
    },
    casesMouseLeave() { //解决方案轮播图
      this.casesActive = ''

    },
    gotoPage(path) { //首页
      this.$router.push(path)
    },
    //获取新闻列表
    getNewsList() {
      axios.post(getNewsListApi, { newsOrProduct: 0, }, {
      }).then(res => {
        if (res.status == 200 && res.data.data.length > 0) {
          // this.newsData = res.data.data
          this.newsData = res.data.data.slice(0, 4)
          let { newsTime, newsDescribe, newsPicture } = this.newsData[0]
          this.newsDateRight = newsTime
          this.newsDateDesc = newsDescribe
          if (newsPicture) this.newsBigImage = newsPicture

          // // console.log(res.data.data.newsContent)
          // this.newsData.forEach(item => {
          //   console.log((item.newsContent).match(/<p[^>]*>([\s\S]*?)<\/p>/)[0])
          // })

        }
      })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 点击跳到新闻详情
    newsClick(url) {
      this.$router.push({ path: url })
    },

    //存访客信息
    saveUserInfo() {
      // returnCitySN["cip"] + ',' + returnCitySN["cname"], returnCitySN
      if (returnCitySN) {
        let params = {
          ip: returnCitySN["cip"],
          address: returnCitySN["cname"],
          content: JSON.parse(JSON.stringify(returnCitySN))
        }

        axios.post(saveUserinfoApi, params).then(res => {
        }).catch(err => {
          console.log(err);
        })
      }

    },
    neKnowMore() { //跳转到新闻列表
      this.$router.push('/news')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .swiper-scrollbar-drag {
  background: #2A5CE9 !important;
}



.Index {
  .top-banner {
    max-width: 1920px;
    max-height: 752px;
    // background-color: skyblue;
    background: rgba(42, 92, 233, .6);
    margin: 0 auto;
    position: relative;

    .top-img {
      width: 100%;
      height: 100%;
    }

    .top-banner-white {
      position: absolute;
      // top: 50%;
      /*       left: 50%;
      transform: translate(-50%, -50%); */
      left: 0;
      right: 1px;
      bottom: -89px;
      // top: 0;
      margin: auto;


      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      height: 178px;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1600);
      border-radius: 3px 3px 3px 3px;
      z-index: 999;

      .banner-icon-container {
        position: relative;
        height: 100%;
        text-align: center;
        padding: 27px 28px 47px;
        box-sizing: border-box;
        // flex: 1;
        min-width: calc(100% / 7);



        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 50%;
          right: 0;
          top: 50px;
          background-color: #E7E7E7;
        }

        &:last-child::after {
          content: "";
          display: block;
          position: absolute;
          width: 0px;
          height: 50%;
          right: 0;
          top: 50px;
          background-color: #E7E7E7;
        }

        img {
          width: 36px;
          height: 36px;
          margin-bottom: 20px;

        }

        .name {
          // height: 40px;
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          // color: #FFFFFF;
          // line-height: 20px;
        }
      }

      .banner-icon-active {
        //选中的效果
        background: #2A5CE9;
        color: #fff;

        // 可以优化一下
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 50%;
          right: 0;
          top: 50px;
          background-color: #2A5CE9;
        }

        &:last-child::after {
          content: "";
          display: block;
          position: absolute;
          width: 0px;
          height: 50%;
          right: 0;
          top: 50px;
          background-color: #2A5CE9;
        }
      }
    }
  }


  //服务优势
  .serve-container {
    height: 779px;
    box-sizing: border-box;
    // background-color: skyblue;


    h3 {
      height: 56px;
      font-size: 40px;
      font-weight: bold;
      color: #000000;
      line-height: 56px;
      text-align: center;
      padding: 160px 0 74px;
      // background-color:red;
    }

    .serve-box {

      display: flex;
      justify-content: space-between;
      align-items: center;


      //选中的效果
      .serve-content-actived {
        box-shadow: 0px 3px 9px 1px rgba(21, 113, 178, 0.0700);

        .desc {
          color: #8B8B8B !important;
        }
      }

      .serve-content {
        width: 281px;
        // height: 402px;
        // height: 350px;
        height: 315px;
        background: #FFFFFF;
        // box-shadow: 0px 3px 9px 1px rgba(21, 113, 178, 0.0700);
        // box-shadow: 0px 3px 9px 1px rgba(21, 113, 178, 0.0700);
        // border: 1px solid #F5F5F5;
        // box-sizing: border-box;
        // padding: 38px 57px 58px;
        padding: 38px 0 58px;
        cursor: pointer;
        margin-right: 10px;



        img {
          display: block;
          width: 167px;
          height: 145px;
          margin: 0 auto;
        }

        .title {
          text-align: center;
          margin: 16px 0 20px;
          height: 40px;
          font-size: 28px;
          line-height: 40px;

        }

        .desc {
          width: 238px;
          font-size: 14px;
          line-height: 25px;
          // color: #8B8B8B;
          color: #fff;
          text-align: justify;
          // letter-spacing: 3px;
          margin: 0 auto;

        }

        // &:hover .desc {
        //   color: #8B8B8B;
        // }
      }
    }

  }

  //解决方案
  .solution {
    max-width: 1920px;
    height: 729px;
    // overflow: hidden;
    box-sizing: border-box;
    background: url("../assets/images/home/solution-bg.png");
    // background-size: cover;
    padding-top: 47px;
    margin: 0 auto;

    .solution-title {
      width: 350px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;

      .dot {
        display: block;
        width: 77px;
        height: 11px;
      }

      h3 {
        text-align: center;
        width: 160px;
        height: 56px;
        font-size: 40px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 56px;
      }
    }

    // 轮播图
    .Multi-line {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      line-clamp: 6;
      -webkit-line-clamp: 6; //显示几行
    }

    .tow-line {
      //超出两行省略号
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2; //显示几行
    }

    .swiper-container {
      width: 1480px;
      height: 485px;
      margin-top: 40px;
    }

    .swiper-slide {
      width: auto;
      position: relative;

      .photo {
        display: flex;
        // align-content: flex-end;
        width: 350px;
        height: 465px;
        overflow: hidden;
        cursor: pointer;
        margin-right: 20px;
        flex-direction: column-reverse;
        // background: linear-gradient(180deg,
        //     rgba(255, 255, 255, 0) 0%,
        //     #000000 100%);
        border-radius: 0px 0px 6px 6px;

        .photo-item {
          // background: rgba(0, 0, 0, 0.1);
          // box-shadow: 0px 3px 12px -4px #000;

          background: linear-gradient(180deg,
              rgba(255, 255, 255, 0) 0%,
              #000000 100%);
          overflow: hidden;
        }

        .title {
          font-size: 19px;
          font-weight: bold;
          color: #ffffff;
          margin-top: 15px;
          margin-bottom: 16px;
          margin-left: 23px;
          text-align: left;
        }

        .content {
          font-weight: bold;
          color: #ffffff;
          font-size: 12px;
          margin-bottom: 24px;
          margin-left: 23px;
          width: 294px;
          min-height: 34px;
          text-align-last: left;
          line-height: 17px;
        }
      }
    }

    @keyframes slidein {
      from {
        transform: translateY(-100%);
      }

      to {
        transform: translateY(0px);
      }
    }
  }

  //新闻资讯
  .news-container {
    // background: skyblue;
    box-sizing: border-box;
    padding-top: 40px;

    .news {
      .title-en {
        // width: 352px;
        height: 98px;
        list-style: 98px;
        font-size: 70px;
        font-weight: bold;
        color: #2A5CE9;
      }

      .title-zn {
        overflow: hidden;
        // width: 160px;
        height: 56px;
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 40px;

        .news-more {
          float: right;
          font-size: 18px;
          font-weight: normal;

          &:hover {
            color: #2a5ce9;
            cursor: pointer;
          }
        }
      }

      .news-item-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 72px;

        .list {
          // background: rgb(226, 89, 89);
          // height: 420px;

          //选中的效果
          .news-active {
            box-sizing: border-box;
            width: 598px;
            height: 188px;
            background: #2A5CE9;
            border-left: 8px solid #21CECA;
            border-radius: 3px 3px 3px 3px;
            color: #fff;
            cursor: pointer;
            // margin-bottom: 37px;

            // &:last-child {
            //   margin-bottom: 0px;
            //   margin-top: 37px;
            // }

          }

          //未选中
          .news-unactive {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            max-width: 598px;

            // background: red;

            .time {
              height: 25px;
              font-size: 18px;
              color: #000000;
              line-height: 25px;
              // background: red;
              margin-top: 20px;
              flex: 0 0 92px;
              min-width: 110px;
            }

            .news-list-title {
              // background: red;
              height: 25px;
              font-size: 18px;
              color: #000000;
              line-height: 25px;
              margin-left: 63px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

          }

          .list-container {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #E6E6E6;

            .small-image {
              display: block;
              width: 203px;
              height: 141px;
              margin: 0 20px 0 10px;
            }

            .list-news-text {
              box-sizing: border-box;


              .time {
                height: 25px;
                font-size: 18px;
                line-height: 25px;
                margin-bottom: 19px;
              }

              .news-list-title {
                // width: 324px;
                height: 25px;
                font-size: 18px;
                line-height: 25px;
                // margin-left: 63px;
                // margin-top: 19px 0 21px;
                // flex: 1;

                // overflow: hidden;
                // text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
              }

              .more {
                display: block;
                width: 14px;
                height: 13px;
                margin-top: 40px;
              }
            }

          }


        }

        .big-img {
          // background: rgb(226, 89, 89);
          position: relative;
          overflow: hidden;
          width: 531px;
          // height: 420px;
          height: 383px;
          margin-left: 69px;


          .news-right-img {
            display: block;
            width: 100%;
            // height: 100%;
            // object-fit: fill;
          }

          .modal-text {
            position: absolute;
            bottom: 0;
            background: linear-gradient(180deg,
                rgba(255, 255, 255, 0) 0%,
                #000000 100%);
            // background: rgba(0, 0, 0, .1);
            // box-shadow: 0px 3px 12px -5px #000;
            padding: 0 24px 0 24px;
            color: #fff;


            .time {
              margin-top: 15px;
            }

            .title {
              margin: 8px 0 10px;
              text-align: justify;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4; // 控制多行的行数
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }


  // 联系我们
  .contact-us-container {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    max-width: 1920px;
    height: 729px;
    // background: skyblue;
    background-image: url(../assets/images/home/contact-us-bg.png);
    margin: 0 auto;

    .contact-us-content {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      box-sizing: border-box;


      .text {
        width: 590px;
        height: 516px;
        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        opacity: 0.9;
        box-sizing: border-box;
        // padding: 0 60px 0 34px;

        .title {
          text-align: center;
          height: 50px;
          font-size: 36px;
          font-weight: bold;
          color: #000000;
          line-height: 50px;
          margin: 39px 27px;
        }

        .desc {
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          line-height: 40px;
          margin: 0 60px 0 34px;
        }

        .btn {
          width: 136px;
          height: 44px;
          line-height: 44px;
          background: #2A5CE9;
          font-size: 18px;
          color: #fff;
          text-align: center;
          margin: 41px 0 26px 22px;
          border-radius: 50px;
          box-sizing: border-box;
          cursor: pointer;
        }

        .logo {
          display: block;
          width: 540px;
          height: 78px;
          margin: 0 38px 0 12px;
        }
      }

      .img {
        position: relative;
        width: 517px;
        height: 427px;
        background: #2A5CE9;
        border-radius: 3px 3px 3px 3px;
        margin-left: 93px;

        img {
          position: absolute;
          display: block;
          width: 608px;
          height: 502px;
          top: -89px;
          left: -109px;
        }

      }
    }
  }
}
</style>
